import React, { Fragment } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import { Grid, CircularProgress } from '@material-ui/core';
import './style.css'

import { SnackbarContentWrapper } from '../CommonCustomComponents/SnackBar';

import { isObjectInvalid } from '../utils';
import { stringify } from '../properties';
import httpHelper from '../httpHelper';
import DetailsTable from './DetailsTable';
import Header from './Header';



const defaultDistanceData = {
    distanceText: '',
    durationText: '',
    distance: null
};

const invaliDistanceData = {
    distanceText: '---',
    durationText: '---',
    distance: null
};

const defaultPrices = {
    cashDiscountPrice: '',
    regularPrice: ''
};

const invalidPrices = {
    cashDiscountPrice: '---',
    regularPrice: '---'
};

const PRICE_TYPE = {
    1: 'CashDiscountPrice',
    2: 'RegularPrice',
    'CashDiscountPrice': 1,
    'RegularPrice': 2
};

export default class ResultScreen extends React.Component {
    _distanceRequest = null;
    _priceRequest = null;

    constructor(props) {
        super(props);

        this.changePriceType = this.changePriceType.bind(this);
        this.handleChange = this.handleChange.bind(this);

        const data = this.props.data;

        this.state = {
            selectedPriceType: PRICE_TYPE.CashDiscountPrice,
            loading: true,
            openSnackbar: false,
            disabledBooking: true,
            data: {
                ...defaultDistanceData,
                ...defaultPrices,
                ...data
            }
        };
    }

    componentDidMount() {
        Promise.all([
            this.calculateDistanceData(this._getReqParamsToCalculateDistance()),
            this.calculatePrices(this._getReqDataToCalculatePrice())
        ]).then(() => {
            this.setState({ loading: false });
        }).then(() => {
            setTimeout(() => this.sendEmails());
        });
    }

    componentWillUnmount() {
        [this._distanceRequest, this._priceRequest].forEach(req => {
            req && req.cancelTokenSource.cancel();
        });
    }

    _isEqualByKeys(oldData, newData, comparedKeys) {
        let isEqual = true;
        const length = comparedKeys.length;

        for (let i = 0; i < length; i++) {
            const key = comparedKeys[i];
            if (oldData[key] !== newData[key]) {
                isEqual = false;
                break;
            }
        }

        return isEqual;
    }


    _isValidPlaces(data) {
        return data.placeFromId && data.placeToId;
    }

    handleDistanceChange(oldData, newData) {
        const reqParams = this._getReqParamsToCalculateDistance(newData);

        if (this._isValidPlaces(newData)) {
            if (!this._isEqualByKeys(this._getReqParamsToCalculateDistance(oldData), reqParams,
                ['placeFromId', 'placeToId'])) {
                this.calculateDistanceData(reqParams);
            }
            return;
        }

        this.setState({
            data: { ...this.state.data, ...invaliDistanceData },
            disabledBooking: true,
        });
    }

    handlePriceChange(oldData, newData) {
        const reqData = this._getReqDataToCalculatePrice(newData);

        if (!isObjectInvalid(reqData)) {
            if (!this._isEqualByKeys(this._getReqDataToCalculatePrice(oldData), reqData,
                ['placeFromId', 'placeToId', 'openEnclosed', 'running', 'carModelId'])) {
                this.calculatePrices(reqData);
            }
            return;
        }

        this.setState({
            data: { ...this.state.data, ...invalidPrices },
            disabledBooking: true,
        });
    }

    calculateDistanceData(reqParams) {
        if (this._distanceRequest) {
            this._distanceRequest.cancelTokenSource.cancel();
        }

        this.setState({
            // data: { ...this.state.data, ...defaultDistanceData },
            disabledBooking: true
        });

        this._distanceRequest = httpHelper.get('calc/distance', reqParams);

        return this._distanceRequest.response
            .then(distanceData => {
                this._distanceRequest = null;

                distanceData.distance = distanceData.distance.toFixed().valueOf();

                this.setState({
                    data: { ...this.state.data, ...distanceData },
                    disabledBooking: false
                });
            })
            .catch(() => {
                this.setState({
                    data: { ...this.state.data, ...invaliDistanceData },
                    openSnackbar: true
                });
            });
    }

    _getReqParamsToCalculateDistance(data = this.state.data) {
        return {
            placeFromId: data.placeFromId,
            placeToId: data.placeToId
        }
    }

    _getReqDataToCalculatePrice(data = this.state.data) {
        return {
            carModelId: data.vehicleModel && data.vehicleModel.id,
            placeFromId: data.placeFromId,
            placeToId: data.placeToId,
            openEnclosed: data.transportType,
            running: data.running
        };
    }

    calculatePrices(reqData) {
        if (this._priceRequest) {
            this._priceRequest.cancelTokenSource.cancel();
        }

        this.setState({
            // data: { ...this.state.data, ...defaultPrices },
            disabledBooking: true
        });

        this._priceRequest = httpHelper.post('calc/price', reqData);

        return this._priceRequest.response
            .then(prices => {
                this._priceRequest = null;

                this.setState({
                    data: { ...this.state.data, ...prices },
                    disabledBooking: false
                });
            })
            .catch(() => {
                this.setState({
                    data: { ...this.state.data, ...invalidPrices },
                    openSnackbar: true
                });
            });;
    }

    sendEmails(data = this.state.data) {
        const emailTemplateData = {
            cashDiscountPrice: data.cashDiscountPrice.toFixed(2).valueOf(),
            regularPrice: data.regularPrice.toFixed(2).valueOf(),
            shipDate: data.shipDate.toLocaleDateString('en-US'),
            distanceText: data.distanceText,
            durationText: data.durationText,
            clientEmail: data.email,
            clientPhone: data.phone,
            placeFrom: data.placeFrom,
            placeTo: data.placeTo,
            transportRunning: data.running,
            transportType: data.transportType,
            vehicleMaker: data.vehicleMaker,
            vehicleModel: data.vehicleModel.model,
            vehicleYear: data.vehicleYear,
            vehicleCategory: data.vehicleModel.category
        };

        return httpHelper.post('email/notify', emailTemplateData);
    }

    changePriceType(selectedPriceType) {
        if (selectedPriceType !== this.state.selectedPriceType) {
            this.setState({ selectedPriceType })
        }
    }

    handleChange(formValue) {
        const oldData = this.state.data;
        const newData = { ...oldData, ...formValue };

        this.setState({ data: newData });

        // this.handlePriceChange(oldData, newData);
        // this.handleDistanceChange(oldData, newData);

        setTimeout(() => {
            this.handlePriceChange(oldData, newData);
            this.handleDistanceChange(oldData, newData);
        });
    }

    book = () => {
        const data = { ...this.state.data, selectedPriceType: this.state.selectedPriceType };
        const queryString = stringify(data);

        window.location = 'https://www.allcarstransport.com/form?' + queryString;
    };

    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    render() {
        return (
            <div className="result-screen">
                {this.state.loading
                    ? (
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                            style={{ minHeight: '100vh' }}
                        >
                            <CircularProgress size={80} />
                        </Grid>
                    ) : (
                        <Fragment>
                            <Header />

                            <div className="container-fluid mt-5">
                                <div className="row justify-content-center">


                                    <div className="col-12 col-lg-6 information-block order-2 order-lg-1">
                                        <div className="block-title">Information</div>

                                        <div className="w-100 bordered-block">
                                            <p>Your quote has been e-mailed to you.</p>
                                            <p>Make a selection below to continue to booking. <strong>No payment required!</strong></p>
                                        </div>

                                        <div className="option-container">
                                            <div className="option-wrapper">
                                                <div className="option-name">Service type</div>
                                                <div className="option-value">Door to door</div>
                                                <i className="fa fa-info-circle" />
                                            </div>

                                            <div className="option-wrapper">
                                                <div className="option-name">Insurance</div>
                                                <div className="option-value">Included</div>
                                                <i className="fa fa-info-circle" />
                                            </div>
                                        </div>


                                        <div className="prices">

                                            <div className={'price ' + (this.state.selectedPriceType === PRICE_TYPE.CashDiscountPrice ? 'discounted-price' : 'regular-price')}
                                                onClick={() => this.changePriceType(PRICE_TYPE.CashDiscountPrice)}
                                            >
                                                <div className="price-title">Discounted cash price</div>
                                                <div className="price-value">
                                                    {this.state.data.cashDiscountPrice}
                                                </div>

                                                <p className="description">Once the order is assigned to a carrier, a partial payment will be required. The balance will be paid to the carrier at the time of delivery.</p>

                                                {
                                                    this.state.selectedPriceType === PRICE_TYPE.CashDiscountPrice ?
                                                        <div className="selected">
                                                            <i className="fa fa-check-circle" /><span> Selected</span>
                                                        </div> : ''
                                                }
                                            </div>

                                            <div className={'price ' + (this.state.selectedPriceType === PRICE_TYPE.RegularPrice ? 'discounted-price' : 'regular-price')}
                                                onClick={() => this.changePriceType(PRICE_TYPE.RegularPrice)}
                                            >
                                                <div className="price-title">Regular price</div>
                                                <div className="price-value">
                                                    {this.state.data.regularPrice}
                                                </div>

                                                <p className="description">A full payment by credit card or debit card will be required when the order is assigned to a carrier and a truck is dispatched to have your vehicle picked up.</p>

                                                {
                                                    this.state.selectedPriceType === PRICE_TYPE.RegularPrice ?
                                                        <div className="selected">
                                                            <i className="fa fa-check-circle" /><span> Selected</span>
                                                        </div> : ''
                                                }
                                            </div>

                                        </div>

                                        <button disabled={this.state.disabledBooking} onClick={this.book} className="btn main-btn m-auto m-md-0">
                                            <span>Continue to booking details</span>
                                        </button>

                                        <div className="additional-info">
                                            <p>Have questions?</p>
                                            <p>Email sales@allcarstransport.com or call</p><p className="phone-number">(609) 318-4008</p>
                                        </div>

                                    </div>

                                    <DetailsTable data={this.state.data} onChange={this.handleChange} />

                                </div>
                            </div>
                        </Fragment>
                    )}

                <Snackbar
                    open={this.state.openSnackbar}
                    onClose={this.handleCloseSnackbar}
                    autoHideDuration={5000}
                >
                    <SnackbarContentWrapper
                        variant="error"
                        message="Server error. Can not calculate data."
                    />
                </Snackbar>
            </div>
        );
    }
}
import React, { Suspense, lazy, Fragment } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import ParamForms from '../ParamForms';
import ResultScreen from '../ResultScreen';


import './style.css';


const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '14px',
                lineHeight: '1em',
            },

            input: {
                height: '1em'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '14px 14px'
            },
        },

        MuiFormControl: {
            root: {
                '& [class*="MuiInputLabel-outlined"]': {
                    transform: 'translate(14px, 16px) scale(1)',
                },

                '& [class*="MuiInputLabel-outlined"][class*="MuiInputLabel-shrink"]': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
            }
        },
        
        MuiAutocomplete: {
            paper: {
                '& > ul': {
                    maxHeight: '176px',
                }
            },
            option: {
                minHeight: 32,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 10,
                paddingRight: 10,
            },
            // root: {
            //     '& [class*="MuiInputLabel-outlined"]': {
            //         transform: 'translate(14px, 16px) scale(1)',
            //     },

            //     '& [class*="MuiInputLabel-outlined"][class*="MuiInputLabel-shrink"]': {
            //         transform: 'translate(14px, -6px) scale(0.75)',
            //     },
            // },
            inputRoot: {
                '&[class*="MuiOutlinedInput-root"]': {
                    '& $input': {
                        padding: '6px 4px',
                    },
                },
            },

        },
    },
    palette: {
        primary: {
            main: '#f29112'
        },
    },
    typography: {
        htmlFontSize: 18,
        // fontSize: 12,
        body1: {
            fontSize: '14px'
        }
    },
});


const GlobalCss = withStyles({
    '@global': {
        '.MuiOutlinedInput-root': {
            backgroundColor: '#fff'
        },
    },
})(() => null);



const Admin = lazy(() => import('../AdminPage'));
const Widget = lazy(() => import('../Widget'));


const FORM = 'FORM';
const RESULT = 'RESULT';

class AppDefaultContent extends React.Component {
    data = {};
    state = {
        stage: FORM
    };

    constructor(props) {
        super(props);

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit = (formData) => {
        this.data = formData;

        this.setState({ stage: RESULT });
    };

    render() {
        if (this.state.stage === FORM) {
            return (
                <div className="forms-content-wrapper">
                    <div className="form-wrapper">
                        <div className="form">
                            <ParamForms onSubmit={this.onFormSubmit} />
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.stage === RESULT) {
            return (
                <ResultScreen data={this.data} />
            );
        }
    }
}


const inIframe = window.location !== window.parent.location;
export const IframeContext = React.createContext(inIframe);

export default function App() {
    return (
        <Fragment>
            {/* <CssBaseline /> */}
            <GlobalCss />
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Router>
                        <Suspense fallback={<div></div>}>
                            <Switch>
                                <Route exact path="/" component={AppDefaultContent} />
                                <Route path="/admin" component={Admin} />
                                <Route path="/widget" component={Widget} />
                                <Redirect from="**" to="/" />
                            </Switch>
                        </Suspense>
                    </Router>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Fragment>
    );
}

import React from 'react';
import './style.css'

export default function Header () {

    return (
        <header className="header">
            <div className="contact">
                <span>(609) 318-4008</span>
            </div>
        </header>
    )
}